<template>
  <admin>
    <metatag title="Leads"></metatag>

    <page-header>
      <template v-slot:action>
        <can :code="['admin']">
          <v-btn :block="$vuetify.breakpoint.smAndDown" large color="info" exact :to="{ name: 'leads.upload' }">
            <v-icon small left>mdi-cloud-upload</v-icon>
            <span v-text="'Upload'"></span>
          </v-btn>
        </can>
        <can :code="['dealer']">
          <v-btn :block="$vuetify.breakpoint.smAndDown" large color="grey" class="white--text" exact
            @click="exportLeads">
            <v-icon small left>mdi-cloud-download</v-icon>
            <span v-text="'Download'"></span>
          </v-btn>
        </can>
      </template>
    </page-header>

    <v-card class="my-0">
      <toolbar-menu ref="toolbar" :filter-status="true" :filter-dealer="isDisplayDealerFilter"
        :items.sync="tabletoolbar" @update:search="setSearch" @update:status="handleFilterStatus"
        @update:dealer="handleFilterDealer">
      </toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table :headers="leads.headers" :items="items" :options.sync="options" :server-items-length="total"
          :loading="loading" :items-per-page="5" color="primary" item-key="id" class="py-0 text-no-wrap">
          <template v-slot:progress><span></span></template>

          <template v-slot:item.full_name="{ item }">
            <small>{{ item.first_name }} {{ item.last_name }} </small> <br />
            <small>{{ item.email }} </small> <br />
            <small>{{ item.mobile }} </small> <br />
          </template>

          <template v-slot:item.vehicle="{ item }">
            <div v-if="item.variant_name">
              <small>{{ item.model_name }}</small>
            </div>
            <div v-else>
              {{ item.model_name }}
            </div>
            <div v-if="item.variant_name">
              <small>{{ item.variant_name }}</small>
            </div>
            <small v-if="item.color">{{ item.color }}</small>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip label>
              {{ item.status.label }}
            </v-chip>
          </template>

          <template v-slot:item.sc_assgined_date="{ item }">
            <span>
              {{ item.sc_assigned_at ? formatDate(item.sc_assigned_at) : "" }}
            </span>
          </template>

          <template v-slot:item.upload_date="{ item }">
            <span>
              {{ formatDate(item.uploaded_at) }}
            </span>
          </template>

          <template v-slot:item.acceptance_date="{ item }">
            <span>
              {{ item.accepted_at ? formatDate(item.accepted_at, "YYYY-MM-DD") : "" }}
            </span>
          </template>

          <template v-slot:item.date_updated="{ item }">
            <span>
              {{ item.updated_at ? formatDate(item.updated_at) : "" }}
            </span>
          </template>

          <template v-slot:item.assigned_sc="{ item }">
            <span>
              {{
                item.assigned_sc_user
                  ? `${item.assigned_sc_user.first_name} ${item.assigned_sc_user.last_name}`
                  : ""
              }}
            </span>
          </template>

          <template v-slot:item.id="{ item }">
            <div class="text-no-wrap">
              <!-- Preview -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="previewItem(item)" text color="primary" icon v-on="on">
                    {{ item.id }}
                  </v-btn>
                </template>
                <span v-text="'Preview'"></span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.editAction="{ item }">
            <div class="text-no-wrap">
              <!-- Edit -->
              <can :code="['dealer']">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="editItem(item)" text icon v-on="on">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Edit'"></span>
                </v-tooltip>
              </can>
              <!-- Edit -->
            </div>
          </template>
          <template v-slot:loading>
            <v-slide-y-transition mode="out-in">
              <skeleton-avatar-table></skeleton-avatar-table>
            </v-slide-y-transition>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <edit @close:edit="closeEdit" :edit="leadDialog" :item="selectedItem" v-if="leadDialog"></edit>
    <preview @close:preview="closePreview" :preview="previewDialog" :item="selectedItem"></preview>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";
import Preview from "./partials/Preview.vue";
import Edit from "./partials/Edit.vue";

export default {
  components: {
    Preview,
    Edit,
  },
  data() {
    return {
      statusFilter: null,
      loading: false,
      leadDialog: false,
      previewDialog: false,
      dialog: false,
      dialogDelete: false,
      isDisplayDealerFilter: true,
      tabletoolbar: {
        isSearching: false,
        search: null,
        models: [],
        type: "lead",
      },
      selectedItem: {},
      options: {},
      items: [],
      total: 0,
      leads: {
        headers: [
          {
            text: "ID",
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: "Cust. #",
            align: "left",
            value: "customer_id",
            class: "text-no-wrap",
          },
          {
            text: "Uploaded date",
            align: "start",
            sortable: true,
            value: "upload_date",
          },
          {
            text: "Acceptance date",
            align: "center",
            sortable: true,
            value: "acceptance_date",
          },
          {
            text: "Full Name",
            align: "start",
            sortable: true,
            value: "full_name",
          },
          {
            text: "Preferred Dealer",
            align: "start",
            sortable: true,
            value: "dealer_code",
          },
          {
            text: "Vehicle",
            sortable: true,
            value: "vehicle",
          },
          {
            text: "SC Assigned Date",
            align: "start",
            sortable: true,
            value: "sc_assgined_date",
          },
          {
            text: "Sales Consultant",
            align: "start",
            sortable: true,
            value: "assigned_sc",
          },
          {
            text: "Status",
            align: "start",
            sortable: true,
            value: "status",
          },
          {
            text: "Date Updated",
            align: "start",
            sortable: true,
            value: "date_updated",
          },
        ],
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      data: "leads/GET_LEADS",
      userRoles: "auth/GET_ROLES",
      dealerFilter: "dealer/GET_DEALER",
    }),
  },
  async mounted() {
    await this.getUserType();

    let user_roles = this.userRoles.map(a => a.slug);

    if (user_roles.includes("dealer")) {
      this.leads.headers = [
        ...this.leads.headers,
        {
          text: "Actions",
          align: "center",
          value: "editAction",
          class: "muted--text text-no-wrap",
        },
      ];
      this.isDisplayDealerFilter = false;
    }
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.getItems(page, itemsPerPage);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getLeads: "leads/list",
      getUser: "auth/getUser",
      downloadLeads: "leads/download",
      getUserType: "auth/getUserType",
    }),

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.getItems(page, itemsPerPage, e.target.value);
    }, 300),

    getItems(page, itemsPerPage, q = "") {
      let data = {
        page,
        per_page: itemsPerPage,
        q,
      };
      if (this.statusFilter) {
        data.status = this.statusFilter;
      }
      if (this.dealerFilter) {
        data.dealer = this.dealerFilter;
      }
      this.getLeads(data).then((data) => {
        this.items = this.data.data;
        this.total = this.data.meta.total;
        this.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    async handleFilterStatus(val) {
      this.statusFilter = val;
      await this.getItems(1, 5, "");
    },

    async handleFilterDealer() {
      await this.getItems(1, 5, "");
    },

    formatDate(item, format) {
      return helpers.format_date(item, format);
    },

    previewItem(item) {
      this.previewDialog = true;
      this.selectedItem = item;
    },

    closePreview() {
      this.previewDialog = false;
    },

    async closeEdit() {
      this.leadDialog = false;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      await this.getItems(page, itemsPerPage, "");
    },

    editItem(item) {
      this.selectedItem = {
        assigned_sc_user_id: item.assigned_sc_user ? item.assigned_sc_user.id : null,
        ...item,
      };
      this.leadDialog = true;
    },

    async exportLeads() {
      await this.downloadLeads();
    },
  },
};
</script>

<style></style>
