<template>
  <v-dialog v-model="isShow" persistent max-width="900px" class="overflow-hidden">
    <v-card class="pa-4">
      <v-card-title>
        <h2 title="Edit" class="mb-1">#{{ item.id }}</h2>
      </v-card-title>
      <v-card-text class="overflow-y-auto">
        <!-- Background Details -->
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="font-weight-bold">{{ "Customer" }}</td>
                <td>{{ item.title }} {{ item.first_name }} {{ item.last_name }}</td>
                <td class="font-weight-bold">
                  {{ "Activity Source" }}
                </td>
                <td>{{ item.activity_source }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Province" }}</td>
                <td>{{ item.province }}</td>
                <td class="font-weight-bold">{{ "Municipality" }}</td>
                <td>{{ item.municipality }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Barangay" }}</td>
                <td>{{ item.barangay }}</td>
                <td class="font-weight-bold">{{ "Zip Code" }}</td>
                <td>{{ item.zipcode }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Street" }}</td>
                <td>{{ item.street }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Email" }}</td>
                <td>{{ item.email }}</td>
                <td class="font-weight-bold">{{ "Mobile" }}</td>
                <td>{{ item.mobile }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Model" }}</td>
                <td>{{ item.model_name }}</td>
                <td class="font-weight-bold">{{ "Variant" }}</td>
                <td>{{ item.variant_name }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Color" }}</td>
                <td>{{ item.color }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Inquiry Date" }}</td>
                <td>{{ item.created_at ? formatDate(item.created_at) : "" }}</td>
                <td class="font-weight-bold">{{ "Sales Consultant" }}</td>
                <td>
                  {{
                    item.assigned_sc_user
                      ? `${item.assigned_sc_user.first_name} ${item.assigned_sc_user.last_name}`
                      : ""
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Preferred Dealer" }}</td>
                <td>{{ item.dealer_code }}</td>
                <td class="font-weight-bold">{{ "Assigned Date" }}</td>
                <td>{{ item.sc_assigned_at ? formatDate(item.sc_assigned_at) : "" }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Status" }}</td>
                <td>
                  <v-chip label>{{ item.status ? item.status.label : "" }}</v-chip>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Date Created" }}</td>
                <td>{{ item.created_at ? formatDate(item.created_at) : "" }}</td>
                <td class="font-weight-bold">{{ "Acceptance Date" }}</td>
                <td>{{ item.accepted_at ? formatDate(item.accepted_at) : "" }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ "Date Uploaded" }}</td>
                <td>{{ item.uploaded_at ? formatDate(item.uploaded_at) : "" }}</td>
                <td class="font-weight-bold">{{ "Date Updated" }}</td>
                <td>{{ item.updated_at ? formatDate(item.updated_at) : "" }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">
                  {{ "Preferred Communication" }}
                </td>
                <td>{{ item.preferred_communication }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- Background Details -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="closePreview">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as helpers from "@/core/helpers";

export default {
  props: {
    preview: {
      required: false,
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      required: true,
      type: Object,
    },
  },
  watch: {
    preview: {
      immediate: true,
      handler(val) {
        this.isShow = val;
      },
    },
    item: {
      immediate: true,
      handler(val) {
        this.lead = val;
      },
    },
  },
  data() {
    return {
      isShow: false,
      lead: {},
    };
  },
  methods: {
    closePreview() {
      this.$emit("close:preview");
    },

    formatDate(item, format) {
      return helpers.format_date(item, format);
    },
  },
};
</script>
