var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin',[_c('metatag',{attrs:{"title":"Leads"}}),_c('page-header',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('can',{attrs:{"code":['admin']}},[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"info","exact":"","to":{ name: 'leads.upload' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-cloud-upload")]),_c('span',{domProps:{"textContent":_vm._s('Upload')}})],1)],1),_c('can',{attrs:{"code":['dealer']}},[_c('v-btn',{staticClass:"white--text",attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"large":"","color":"grey","exact":""},on:{"click":_vm.exportLeads}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-cloud-download")]),_c('span',{domProps:{"textContent":_vm._s('Download')}})],1)],1)]},proxy:true}])}),_c('v-card',{staticClass:"my-0"},[_c('toolbar-menu',{ref:"toolbar",attrs:{"filter-status":true,"filter-dealer":_vm.isDisplayDealerFilter,"items":_vm.tabletoolbar},on:{"update:items":function($event){_vm.tabletoolbar=$event},"update:search":_vm.setSearch,"update:status":_vm.handleFilterStatus,"update:dealer":_vm.handleFilterDealer}}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"py-0 text-no-wrap",attrs:{"headers":_vm.leads.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"items-per-page":5,"color":"primary","item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('span')]},proxy:true},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]),_vm._v(" "),_c('br'),_c('small',[_vm._v(_vm._s(item.email)+" ")]),_vm._v(" "),_c('br'),_c('small',[_vm._v(_vm._s(item.mobile)+" ")]),_vm._v(" "),_c('br')]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [(item.variant_name)?_c('div',[_c('small',[_vm._v(_vm._s(item.model_name))])]):_c('div',[_vm._v(" "+_vm._s(item.model_name)+" ")]),(item.variant_name)?_c('div',[_c('small',[_vm._v(_vm._s(item.variant_name))])]):_vm._e(),(item.color)?_c('small',[_vm._v(_vm._s(item.color))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.status.label)+" ")])]}},{key:"item.sc_assgined_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.sc_assigned_at ? _vm.formatDate(item.sc_assigned_at) : "")+" ")])]}},{key:"item.upload_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.uploaded_at))+" ")])]}},{key:"item.acceptance_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.accepted_at ? _vm.formatDate(item.accepted_at, "YYYY-MM-DD") : "")+" ")])]}},{key:"item.date_updated",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.updated_at ? _vm.formatDate(item.updated_at) : "")+" ")])]}},{key:"item.assigned_sc",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.assigned_sc_user ? ((item.assigned_sc_user.first_name) + " " + (item.assigned_sc_user.last_name)) : "")+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"primary","icon":""},on:{"click":function($event){return _vm.previewItem(item)}}},on),[_vm._v(" "+_vm._s(item.id)+" ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Preview')}})])],1)]}},{key:"item.editAction",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('can',{attrs:{"code":['dealer']}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Edit')}})])],1)],1)]}},{key:"loading",fn:function(){return [_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('skeleton-avatar-table')],1)]},proxy:true}])})],1)],1),(_vm.leadDialog)?_c('edit',{attrs:{"edit":_vm.leadDialog,"item":_vm.selectedItem},on:{"close:edit":_vm.closeEdit}}):_vm._e(),_c('preview',{attrs:{"preview":_vm.previewDialog,"item":_vm.selectedItem},on:{"close:preview":_vm.closePreview}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }