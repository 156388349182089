<template>
  <v-dialog
    v-model="isShow"
    max-width="600px"
    class="overflow-hidden"
    persistent
  >
    <v-card class="pa-4">
      <v-card-title>
        <h2 title="Edit" class="mb-1">Edit #{{ lead.id }}</h2>
      </v-card-title>
      <v-card-text class="overflow-y-auto">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <SalesConsultant
                :disabled="!requiredSalesConsultant"
                :required="false"
                :dealer="lead.dealer_id"
                :assigned_sc_user_id="lead.assigned_sc_user_id"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12">
              <date-range @update:daterange="setDateRange" :singleDatePicker="true" :opens="'left'" :selectedDate="lead.accepted_at" />
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="models"
                item-text="name"
                item-value="name"
                label="Model"
                return-object
                outlined
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
                @change="handleGetVariant"
                v-model="model"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="vehicles"
                item-text="alias"
                item-value="id"
                label="Variant"
                return-object
                outlined
                clearable
                hide-details
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
                @change="handleGetColors"
                v-model="vehicle"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="active_colors"
                item-text="color.name"
                item-value="color.id"
                label="Color"
                return-object
                outlined
                clearable
                hide-details
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
                v-model="color"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Street / House Number"
                outlined
                clearable
                hide-details
                background-color="selects"
                v-model="lead.street"
              ></v-text-field>
            </v-col>
          </v-row>
          <Address
            :required="false"
            :details="lead"
            @change:address="handleChangeAddress"
            :is_edit="true"
          />
          <PreferredCommunication
            style="margin-top: 12px;"
            :preferred_communication="lead.preferred_communication"
            @change:communication="handleChangeCommunication"
          />
          <v-row style="margin-top: 12px;">
            <v-col cols="12">
              <v-text-field
                label="Activity Source"
                outlined
                clearable
                hide-details
                background-color="selects"
                v-model="lead.activity_source"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                append-icon="mdi-chevron-down"
                :items="statuses"
                item-text="title"
                item-value="slug"
                label="Status"
                outlined
                clearable
                hide-details
                clear-icon="mdi-close-circle-outline"
                background-color="selects"
                v-model="lead.status"
                :rules="[(value) => !!value || 'Status is required']"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="grey"
          exact
          class="ma-1 white--text px-5"
          @click="closeEdit"
        >
          Cancel
        </v-btn>

        <v-btn
          large
          exact
          color="green darken-1"
          class="ma-1 white--text px-5"
          :loading="loadingSave"
          :disabled="loadingSave || disableSave"
          @click="validate()"
        >
          <v-icon left>mdi-content-save</v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as helpers from "@/core/helpers";
import { mapActions, mapGetters } from "vuex";
import { find } from "lodash";
import SalesConsultant from "@/components/SalesConsultant/SalesConsultant";
import Address from "@/components/Address/Address";
import PreferredCommunication from "@/components/PreferredCommunication/PreferredCommunication";

export default {
  components: {
    SalesConsultant,
    Address,
    PreferredCommunication,
  },
  props: {
    edit: {
      required: false,
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      required: true,
      type: Object,
    },
  },
  watch: {
    edit: {
      immediate: true,
      handler(val) {
        this.isShow = val;
      },
    },
    item: {
      immediate: true,
      async handler(val) {
        if (val.id) {
          this.lead = {
            id: val.id,
            variant_id: val.variant_id,
            street: val.street,
            barangay: val.barangay,
            municipality: val.municipality,
            region: val.region,
            province: val.province,
            zipcode: val.zipcode,
            preferred_communication: "",
            activity_source: val.activity_source,
            status: val.status,
            assigned_sc_user_id: val.assigned_sc_user_id,
            model_name: val.model_name,
            accepted_at: {
              startDate: val.accepted_at ? val.accepted_at : new Date(),
              endDate: val.accepted_at ? val.accepted_at : new Date(),
            },
          };
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      variant_colors: "variantColors/GET_VARIANT_COLORS",
      statuses: "leads/GET_LEADS_STATUSES",
      variants: "cars/GET_VARIANTS",
      models: "models/GET_MODELS",
      colors: "colors/GET_COLORS",
      consultant: "report/GET_SALES_CONSULTANT",

    }),
    requiredSalesConsultant() {
      return this.vehicle &&
        this.color &&
        this.model &&
        this.lead.street &&
        this.lead.province &&
        this.lead.municipality &&
        this.lead.barangay &&
        this.lead.zipcode
        ? true
        : false;
    },
  },
  data() {
    return {
      isShow: false,
      disableSave: false,
      loadingSave: false,
      valid: true,
      lead: {
        id: null,
        variant_id: null,
        street: null,
        barangay: null,
        municipality: null,
        region: null,
        province: null,
        zipcode: null,
        preferred_communication: "email",
        activity_source: null,
        status: null,
        assigned_sc_user_id: null,
        model_name: null,
        accepted_at: {
          startDate: new Date(),
          endDate: new Date(),
        },
      },
      model: null,
      vehicles: [],
      active_colors: [],
      vehicle: null,
      color: null,
      dateRange: [
        this.formatDate(new Date(), "YYYY-MM-DD"),
        this.formatDate(new Date(), "YYYY-MM-DD"),
      ],
    };
  },
  async mounted() {
    this.disableSave = true;

    await this.getStatuses();
    await this.getModels();

    if (this.item.model_name) {
      this.model = await find(this.models, { name: this.item.model_name });
      await this.handleGetVariant();
    }

    if (this.item.variant_name) {
      this.vehicle = await find(this.vehicles, {
        name: this.item.variant_name,
      });
      await this.handleGetColors();
    }

    if (this.item.color) {
      this.color = await this.active_colors.find(
        (item) => item.color.id === this.item.color_id
      ); 
    }

    if (this.item.status) {
      this.lead.status = this.item.status.code ? this.item.status.code : "";
    }

    this.disableSave = false;
  },
  methods: {
    ...mapActions({
      getVariantColors: "variantColors/list",
      getStatuses: "leads/getLeadsStatuses",
      getVariants: "cars/list",
      updateLead: "leads/update",
      getModels: "models/list",
      getColors: "colors/list",
    }),

    closeEdit() {
      this.$emit("close:edit");
    },

    formatDate(item, format) {
      return helpers.format_date(item, format);
    },

    setDateRange(datesSelected) {
      this.dateRange = datesSelected;
    },

    async validate() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.loadingSave = true;
      await this.saveLead();
    },

    setInitialDate() {
      this.lead.accepted_at = {
        startDate: this.formatDate(this.item.accepted_at, "YYYY-MM-DD"),
        endDate: this.formatDate(this.item.accepted_at, "YYYY-MM-DD"),
      };
    },

    async handleGetVariant() {
      if (this.model) {
        await this.getVariants({ model: this.model.id }).then((data) => {
          this.vehicles = this.variants.data;
        });
      }
    },

    async handleGetColors() {
      if (this.vehicle) {
        await this.getVariantColors({ variant_id: this.vehicle.id }).then(
          (data) => {
            this.active_colors = this.variant_colors.data;
          }
        );
      }
    },

    handleChangeAddress(address) {
      this.lead.province = address.province
        ? address.province.name
        : this.lead.province;
      this.lead.municipality = address.city
        ? address.city.name
        : this.lead.municipality;
      this.lead.barangay = address.barangay
        ? address.barangay.name
        : this.lead.barangay;
      this.lead.zipcode = address.zipcode ? address.zipcode : this.lead.zipcode;
    },

    handleChangeCommunication(communication) {
      this.lead.preferred_communication = communication.value;
    },

    async saveLead() {
      
      const data = {
        status: this.lead.status,
        street: this.lead.street,
        province: this.lead.province,
        municipality: this.lead.municipality,
        barangay: this.lead.barangay,
        zipcode: this.lead.zipcode,
        activity_source: this.lead.activity_source,
        preferred_communication: this.lead.preferred_communication,
      };

      if (this.dateRange) {
        data.accepted_at = this.dateRange[0];
      }

      if (this.consultant) {
        data.assigned_sc_user_id = this.consultant;
      }

      if (this.vehicle) {
        data.variant_name = this.vehicle.name;
        data.variant_id = this.vehicle.id;
      }

      if (this.model) {
        data.model_name = this.model.name;
      }

      if (this.color) {
        data.color = this.color.color.name;
        data.color_id = this.color.color.id;
      }

      await this.updateLead({
        data,
        id: this.lead.id,
      }).then((data) => {
        this.$refs.form.reset();
        this.loadingSave = false;
        this.closeEdit();
      });

      this.loadingSave = false;
    },
  },
};
</script>
